import React from 'react';
import { LiveblogRealtimeContainer } from '@core-live/live-liveblog-realtime-container';

import { getLiveblogIdByPublicationId } from './utils';

const Liveblog = () => {
  const publicationId = process.env.REACT_APP_PUBLICATION;
  const liveblogId = getLiveblogIdByPublicationId(publicationId);

  if (!liveblogId) {
    return (
        <div>Liveblog ID not set</div>
    );
  }

  const baseUrl = `https://live-api-prod.schibsted.tech/${publicationId}`;
  const postMessageUrl = `${baseUrl}/user-messages/`;
  const getSingleEntryUrlTemplate = `${baseUrl}/entries/{id}`;
  const liveFeedUrl = `${baseUrl}/entries?liveblogId=${liveblogId}&limit=20`;

  return (
    <LiveblogRealtimeContainer
      liveFeedUrl={liveFeedUrl}
      liveId={liveblogId}
      postMessageUrl={postMessageUrl}
      getSingleEntryUrlTemplate={getSingleEntryUrlTemplate}
      AdComponent={null}
      publication={publicationId}
      showSummary
      isStageMode={false}
      isEditable={false}
      fetchEntriesDelay
    />
  );
};

export default Liveblog;
