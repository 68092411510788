import React from 'react';
import { ThemeProvider } from '@core-live/theme-provider';
import { themeABPrepared } from '@core-live/live-liveblog';

import styles from './App.scss';
import Liveblog from '../Liveblog';

function App() {
  return (
    <div className={styles.app}>
      <h1 className={styles.header}>
        <img className={styles.logo} src="./logo-aftonbladet.svg" alt="aftonbladet logotyp" />
      </h1>
      <p className={styles.information}>
        {' '}
        <strong>Vi har för närvarande driftproblem.</strong>
        {' '}
        Men du kan fortfarande få de senaste nyheterna.
      </p>

      <ThemeProvider theme={themeABPrepared}>
        <Liveblog />
      </ThemeProvider>
    </div>
  );
}

export default App;
